import React from "react"
import Player from "../../blocks/player"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  player: playersYaml(slug: { eq: "lemajic" }) {
    ...PlayerInfo
  }
  stats: allStatsYaml {
    nodes {
      key
      value_ru
      average
    }
  }
}`

export default class Lemajic extends Player {
  renderDescription() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>
          Высокий форвард, сильный при игре на втором этаже, умеющий действовать при стандартах и выбирать позицию под оптимально точный удар головой. У защитников за его счет есть всегда вариант выйти в атаку через длинный пас, так как процент верховых мячей, выигранных Лемайичем, очень высок.
          </p>
          <a href="../player-reports/lemajic-ru.pdf" className="pdf-link" target="_blank">
            Отчёт по игроку
          </a>
          <p>
          Принимая мяч в штрафной, всегда готов к удару в касание. При не самой высокой скорости с мячом очень устойчив, расчетлив, удерживает мяч, хорошо видит поле, читает действия партнеров.
          </p>
          <div className="video">
            <iframe
              title={ this.fullName() }
              src="https://www.youtube.com/embed/-GN4_RbU7N4"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
          <p>
          Сильные спринты при игре без мяча, что позволяет оказывать давление на защитников при длинных «бесхозных» передачах.
          Выступал за Примаверу итальянской «Пармы».
          </p>
          <p>
          В сезоне 2018 завоевал приз лучшего бомбардира в высшей лиге чемпионата Латвии, а так же в составе ФК «Рига» стал чемпионом и обладателем кубка Латвии.
          </p>
          <img src="/images/players/lemajic/photo1.jpg" alt={ this.fullName() } />
        </div>
      </div>
    )
  }
}